import axios from './axios'

// recommended 内部推荐   /api/v1/recommended
export function recommended(unit, name, work_number, mobile, rec_name, rec_mobile, rec_id_card) {
    return axios({
        url: 'api/v1/recommended',
        method: 'POST',
        params: {
            unit,
            name,
            work_number,
            mobile,
            rec_name,
            rec_mobile,
            rec_id_card

        }
    })
}
//应聘  /api/v1/apply

export function apply(name, mobile, id_card, company) {
    return axios({
        url: 'api/v1/apply',
        method: 'POST',
        params: {
            name,
            mobile,
            id_card,
            company,
            /* unit, */
            /* department, */
            /* position, */
            /*  expect_wage */
        }
    })
}
//登记入职   userBackup


export function userBackup(data) {
    return axios({
        url: 'api/v1/userBackup',
        method: 'POST',
        params: data
    })
}

//获取文章的内容  http://{{host}}/api/v1/article/:id

export function article(id) {
    return axios({
        url: '/api/v1/article/' + id,
        method: 'GET',
    })
}

//蓝领工厂列表
export function applyList() {
    return axios({
        url: '/api/v1/apply-factory',
        method: 'GET',
    })
}

//招聘工厂列表
export function factoryList(page) {
    return axios({
        url: '/api/v1/factory-list',
        method: 'GET',
        params: {
            page
        }
    })
}

//置顶工厂列表
export function hostList() {
    return axios({
        url: '/api/v1/host-factory-list',
        method: 'GET',
    })
}

//轮播图列表

export function ImageList() {
    return axios({
        url: '/api/v1/swiper-images',
        method: 'GET',
    })
}

export function factoryDetail(id) {
    return axios({
        url: '/api/v1/factory-detail',
        method: 'POST',
        params: {
            id
        }
    })
}

export function globalData() {
    return axios({
        url: '/api/v1/global-data',
        method: 'GET'
    })
}